// |------------------------------------------------------
// |------------------------------------------------------
// | Overrides
// |------------------------------------------------------
// |------------------------------------------------------

body
	letter-spacing: -0.015em

strong
	color: inherit

.button.is-gradient
	color: $white
	border: 0
	background-image: linear-gradient(50deg, #0076FF, #69E5C1)
	&:hover
		background-image: linear-gradient(50deg, #006ae3, #4ecba8)

.name
	font:
		size: 19px
		weight: $weight-bold
	line-height: 1
	padding-top: 5px
	margin-bottom: 0
	color: $black

.title
	color: $primary
	font:
		size: 1.8rem
		weight: $weight-bold
	letter-spacing: -0.04em
	span > span
		font-weight: $weight-semibold

.button
	&.export
		min-width: 100px
		margin: 2px spacing() 0 0
	&.create
		padding: spacing(2) $block-spacing * 1.275
		> span
			display: flex
			justify-content: center
			align-items: center
			padding-top: 2px
	&.save
		padding: spacing(2) $block-spacing * 2

.button
	border-width: 0.1rem
	font-weight: $weight-semibold
	// span
	// 	position: relative
	// 	top: -1px

.vue-swatches
	@include tablet
		margin-top: 3px
	@include mobile
		width: 100%
	&--is-empty
		border-width: 1px !important
	&__trigger
		@include mobile
			border-radius: 2px!important
			width: 100%!important
			height: 50px!important
			border-radius: $radius!important
		&:hover
			border-color: $grey-light
		&__wrapper
			@include mobile
				width: 100%
	&__diagonal
		@include mobile
			display: none
	&__fallback
		&__wrapper
			display: flex
			padding: 7px 0 0 0 !important
			border-top: 1px solid $grey-lightest
		&__input
			padding: 0
			background: transparent
			border: none
			&::-webkit-color-swatch,
			&::-webkit-color-swatch-wrapper
				border-radius: 5px
		&__button
			text-transform: uppercase
			background: transparent
			color: $primary
			font-weight: $weight-semibold

.svg
	fill: $primary
	transition: $transition-base
	.fill,
	.rect
		fill: $primary
	.stroke
		stroke: $primary
	&__icon
		position: relative
		width: 25px
		height: 100%
		fill: inherit
		vertical-align: middle
		.criteria &
			width: 21px
			top: -1px
			margin-left: 2px

.control.has-icons-left .icon,
.control.has-icons-right .icon
	height: 100%

.toast
	text-transform: lowercase
	div:first-letter
		text-transform: uppercase
	small
		display: block

.notices,
.notices .toast
	z-index: 9999 !important

.tooltip-content
	font-weight: $weight-light

.stroke
	stroke: $black

.datepicker .dropdown-content
	box-shadow: 0 0 15px rgb(10 10 10 / 20%)!important
	.field .select
		font-size: 14px
		max-height: 40px
		min-width: 80px
	.datepicker-table .datepicker-body .datepicker-cell.is-today
		color: $secondary
		border-color: $white
		font-weight: $weight-semibold

.multiselect__option--highlight,
.multiselect__option--selected
	font-weight: normal!important

.multiselect__option--selected span
	font-weight: $weight-semibold

.loading-overlay .loading-background
	background: $white-ter

.has-label
	position: relative
	margin-top: 30px

.carousel-list.has-shadow
	box-shadow: none!important

.b-checkbox.checkbox input[type=checkbox] + .check
	background-color: $white
	border-color: $grey-lighter
	box-shadow: none!important

.quillWrapper .ql-snow.ql-toolbar .ql-formats
	margin-bottom: 4px!important

.ql-toolbar.ql-snow .ql-formats
	margin-right: 5px !important

.ql-snow .ql-toolbar button svg,
.quillWrapper .ql-snow.ql-toolbar button svg
	width: 18px!important
	height: auto!important

.ql-align,
.ql-color
	top: 0!important

.ql-background
	top: -2px!important

.modal-background
	background: rgba($primary, 0.85)
