// |------------------------------------------------------
// |------------------------------------------------------
// | Blocks
// |------------------------------------------------------
// |------------------------------------------------------

@use 'sass:math'

.block
	display: flex
	align-items: center
	justify-content: flex-start
	position: relative
	padding: spacing(2) spacing(1.5)
	background: $white
	min-height: 80px
	border-radius: 3px
	box-shadow: $shadow
	border: 1px solid transparent
	border-left: 5px solid $grey-light
	transition: $transition-base
	&.secondary
		border-left-color: $secondary
	&.inactive
		// background: rgba($white, 0.15)
		border-left-color: $grey-lighter!important
	&.placeholder
		pointer-events: none
	&:hover
		// margin-top: -3px
		opacity: 1
		background: $white
		border-left-color: $primary!important

	&__content
		padding: 0 spacing(1.5)
		display: flex
		flex-direction: column
		line-height: 1.2
		width: calc(100% - 50px)

	&__role
		display: block
		text-indent: -9999px
		width: 8px
		height: 8px
		background: $grey-light
		border-radius: 100%
		border: 1px solid $white

	&__avatar
		display: flex
		color: $white
		align-items: center
		justify-content: center
		background: $grey-lighter
		border-radius: 100%
		font:
			size: 1.2rem
			weight: $weight-semibold
		.b-tooltip
			position: absolute
			top: 0
			right: 5px
			z-index: 2

		.image
			width: 100%

	&__name
		color: $grey
		font-weight: $weight-semibold
		&--user
			padding-top: 0.45rem
		.b-skeleton
			margin-top: 0

	&__email
		text-transform: lowercase

	&__email,
	&__address
		color: $grey-light
		font:
			weight: $weight-light
			size: 0.85em
		&.secondary
			margin-top: 3px

	&__state
		text-transform: uppercase
		font:
			size: 14px
			weight: $weight-light
		color: $grey-lighter
		user-select: none
		padding: spacing(1.5) spacing(1.5) 0 0

	&__status
		margin-right: 25px
		margin-top: 6px
		padding: 0
		svg
			width: 35px

	&__checkbox
		position: absolute
		bottom: -5px
		right: -26px
		opacity: 1!important

	&__dropdown
		position: absolute
		top: 50%
		right: 15px
		margin: -13px 0 0 0

		.dropdown-content
			width: 100%
			border: 1px solid $white-ter
			.svg
				position: relative
				top: -1px
				margin-right: 3px
				svg
					position: relative
					height: auto
					width: 13px
					// margin-right: 3px
				&.profile,
				&.check
					.fill
						fill: $info
				&.check
					svg
						width: 17px
						left: -3px
				&.profile
					svg
						width: 15px
						left: 0
						top: -1px
				&.criteria
					.fill
						fill: $warning
				&.money
					svg
						width: 15px
					.fill
						fill: $success
				&.edit
					svg
						width: 11px
						margin-left: 2px
						margin-right: 2px
				&.trash
					top: -2px
					svg
						width: 16px
						margin-right: 0
				&.restore
					top: 0

		.dropdown-item
			font-weight: $weight-medium
			&.disable
				.restore
					display: inline-block
					transform: rotate(180deg)
					top: 1px

		.dots
			opacity: 0.3
			border: none
			cursor: pointer
			&:hover
				opacity: 0.5

.transaction
	cursor: pointer
	border-left-color: $secondary
	border: 0
	.block__content
		width: 100%
		padding: 0
		display: flex
		flex-direction: row
		align-items: center
	.identification
		width: 35%
	.users
		width: 65%
		text-align: right
		p
			white-space: nowrap
			strong
				text-transform: none
	.token,
	.hash
		color: $secondary
		font-weight: $weight-semibold
		font-size: 0.85em
	.hash
		color: $dark
	.sender,
	.receiver
		color: $grey-light
		font:
			weight: $weight-light
			size: 0.85em
	&:hover
		color: white
		border-left-color: rgb(11, 11, 87)
		background: linear-gradient(50deg, rgb(11, 11, 87), rgb(0, 0, 50))
		.hash,
		.token,
		.sender,
		.receiver
			color: white
	&.from
		.token
			color: $success
		.receiver
			display: none
	&.to
		.token
			color: $danger
		.sender
			display: none
